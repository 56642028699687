import React from "react";
import { graphql } from "gatsby";
import { Grid, Paper, Typography, withStyles } from "@material-ui/core";
import { Helmet } from "react-helmet";

const Post = ({
	data: {
		wordpressPost: { content, date, title }
	},
	classes
}) => (
	<>
		<Helmet>
			<title>{title}</title>
		</Helmet>

		<div className={`container ${classes.rootContainer}`}>
			<Grid container>
				<Grid item xs={12}>
					<Paper elevation={4} className={classes.post}>
						<Typography variant="h5" component="h1">
							{title}
						</Typography>
						<Typography className={classes.dateline}>{date}</Typography>
						<div
							className={classes.postBody}
							dangerouslySetInnerHTML={{ __html: content }}
						/>
					</Paper>
				</Grid>
			</Grid>
		</div>
	</>
);

const styles = ({ spacing, palette }) => ({
	dateline: {
		color: palette.text.secondary
	},
	rootContainer: {
		paddingBottom: spacing.unit * 4
	},
	post: {
		paddingLeft: spacing.unit * 2,
		paddingRight: spacing.unit * 2,
		paddingTop: spacing.unit * 2,
		paddingBottom: spacing.unit * 2
	},
	postBody: {
		marginTop: spacing.unit * 2,
		overflowWrap: "break-word"
	}
});

export default withStyles(styles)(Post);

export const pageQuery = graphql`
	query BlogPostBySlug($slug: String!) {
		wordpressPost(slug: { eq: $slug }) {
			id
			excerpt
			date(formatString: "MMMM Do, YYYY")
			slug
			content
			title
		}
	}
`;
